import React from "react"
import { ChakraProvider, CSSReset, extendTheme } from "@chakra-ui/react"
import { Global } from '@emotion/react';

const Fonts = () => (
  <Global styles={`
    @font-face {
      font-family: 'Rubik';
      font-style: italic;
      font-weight: 300;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0Uw.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: italic;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0Uw.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: italic;
      font-weight: 500;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0Uw.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: italic;
      font-weight: 600;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFEUw.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: italic;
      font-weight: 700;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUw.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: italic;
      font-weight: 800;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8vdFEUw.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: italic;
      font-weight: 900;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8v0FEUw.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UA.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UA.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 600;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UA.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UA.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 800;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-h4-1UA.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 900;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/rubik/v11/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-ro-1UA.ttf) format('truetype');
    }
  `} />
)

const theme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: "inherit"
        }
      },
      variants: {
        "card": {
          shadow: "lg",
          borderRadius: "1rem",
          _hover: {
            shadow: "xl"
          }
        }
      }
    }
  },
  fonts: {
    heading: "Rubik",
    body: 'Rubik'
  },
  colors: {
    brand: {
      50: '#ffe8fe',
      100: '#f1c2f0',
      200: '#e59ae4',
      300: '#d972d7',
      400: '#ce4bcb',
      500: '#b431b2',
      600: '#8d268b',
      700: '#661a64',
      800: '#3d0e3e',
      900: '#180218',
      primary: '#922790'
    }
  }
})

export const wrapRootElement = ({ element }) => {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <CSSReset />
      {element}
    </ChakraProvider>
  )
}